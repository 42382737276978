import React, { useEffect, useState } from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Alert } from '@hhs/ui/old-abyss/ui/base/Alert';
import { event } from '@abyss/web/tools/event';
//import { useRouter } from '@abyss/web/hooks/useRouter';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { classNames } from '@abyss/web/tools/classNames';
import { PageHeader } from 'src/app/App/PageHeader';
import { PageLayout } from 'src/app/App/PageLayout';
import { Flex } from '@abyss/web/ui/Flex';
import { ReCaptcha } from '@hhs/ui/src/utility/ReCaptcha';
import { Button } from '@hhs/ui/src/base/Button';
import { config } from '@abyss/web/tools/config';
import { IconAccountBalances } from '@hhs/ui/src/base/Icon/Provider/IconAccountBalances';
import { useFormState } from '@hhs/ui/old-abyss/ui/form/hooks/useFormState';
import { useAttestation } from 'src/state/attestation';
import { useSteps } from 'src/state/steps';
import { getMessage } from 'src/tools/messages';
import { styles } from './MedicareVerification.styles';
import { ACHTINTable } from './ACHTINTable';
import { CheckTINTable } from './CheckTINTable';
import { invalid } from 'moment';
import {useNavigate} from "react-router-dom";

const RECAPTCHA_KEY = config('RECAPTCHA_KEY_EP');

export const MedicareVerification = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const form = useForm();
  const { setStep } = useSteps();
  const formState = useFormState('medicareVerification');
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const { attestations, confirmTinInfo } = useAttestation();
  const validTINs = Object.values(attestations).filter(
    item => item.valid && !item.restrictAttestation
  );
  const restrictedTINs = Object.values(attestations).reduce((arr, item) => {
    return arr.concat(
      item.valid && item.restrictAttestation ? item.maskedTin : []
    );
  }, []);
  const ACHTINs = validTINs.filter(item => !item.checkPayment);
  const checkTINs = validTINs.filter(item => item.checkPayment);
  const invalidTINs = Object.values(attestations).reduce((arr, item) => {
    return arr.concat(!item.valid ? item.maskedTin : []);
  }, []);

  const handleReCaptchaClick = () => {
    setLoading(true);
  };

  const handleContinue = (e, tokens) => {
    setError();
    form.submit('medicareVerification', data => {
      const payload = Object.keys(data).reduce((arr, key) => {
        const formData = data[key];
        if (formData && attestations[key]) {
          const attestationData = attestations[key];
          arr.push({
            ...attestationData,
            ...formData,
            token: (tokens || []).pop(),
          });
        }

        return arr;
      }, []);

      const billingTINs = payload.map(value => value.tin).join(',');
      event('MEDICARE_VERIFICATION_CONTINUE', { billingTINs });

      confirmTinInfo(
        payload,
        () => {
          setLoading(false);
          navigate('/step/4');
        },
        respError => {
          setLoading(false);
          let errorType = 'Error';
          let errorMessage = respError.message;

          if (respError.code === 'InvalidData') {
            errorType = 'Data Mismatch';
            errorMessage = getMessage('confirm-tins[InvalidData]');
          }

          event('CONFIRM_TINS_ERROR', {
            errorType,
            errorMessage,
            errorCode: respError.code,
            payload,
          });

          setError(respError);
        }
      );
    });
  };

  useEffect(() => {
   
    // handle the page refersh event.
    if(Object.values(attestations).length == 0) {      
         
      setStep(1);
      navigate('/step/1');
    } else {
      setStep(3);
      event('MEDICARE_VERIFICATION_PAGE_VIEW');
  }
   
  }, []);

  return (
    <React.Fragment>
      <PageHeader>
        <PageHeader.Title>Step 3 Verify Payment Information</PageHeader.Title>
      </PageHeader>
      <PageLayout>
        <Flex className={classes.spacing}>
          <Flex.Content>
          <Flex direction='row' alignContent='stretch'>
            <IconAccountBalances className={classes.headerIcon} size={36} />
                <h2>Verify Payment Information</h2>
                <div className={classes.horizontalLine} />
            </Flex>
            <div className={classes.subHeading}>
              Relief fund payments are made to your billing entity account via
              Optum Bank with "HRSAPRFPMT" as the payment description. Please
              confirm the account number and payment(s) you received for each
              TIN. If you have not yet received payment, please call the
              toll-free Provider Support Line at (866) 569-3522.
            </div>
            <If condition={ACHTINs.length}>
              <Then>
                <div className={classes.section}>
                  <ACHTINTable
                    validTINs={ACHTINs.concat(checkTINs)}
                    setError={setError}
                    isLoading={isLoading}
                  />
                </div>
              </Then>
            </If>
            <If condition={checkTINs.length}>
              <Then>
                <div className={classes.section}>
                  <CheckTINTable
                    validTINs={ACHTINs.concat(checkTINs)}
                    setError={setError}
                    isLoading={isLoading}
                  />
                </div>
              </Then>
            </If>
            <If condition={invalidTINs.length}>
              <Then>
                <div className={classNames(classes.section, classes.cardContent)}>
                  <h4 className={classes.cardHeader}>
                    <b>Ineligible TIN(s)</b>
                  </h4>
                  <div className={classes.cardText}>
                    The Billing TIN(s) you entered may be eligible for Relief Fund
                    payment; however, we do not have banking information on file.
                    Please return to this site once you have received payment via
                    Automated Clearing House (ACH) deposit or paper check. If you
                    believe you have received this message in error, please visit{' '}
                    <a
                      href={config('CORONAVIRUS_GOV_URL')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.accessiblityLink}
                    >
                      hrsa.gov/provider-relief
                    </a>{' '}
                    or call the Provider Support Line at (866) 569-3522.
                    <div className={classes.cardTextFooter}>
                      Billing ID Number (TIN):
                      <ul>
                        { invalidTINs.map((tin) => (
                          <li key={tin}>{tin}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Then>
            </If>
            <If condition={restrictedTINs.length}>
              <Then>
                <div className={classNames(classes.section, classes.cardContent)}>
                  <h4 className={classes.cardHeader}>
                    <b>Payment Not Found</b>
                  </h4>
                  <div className={classes.cardText}>
                    This portal is for attesting to Provider Relief Fund Phase 1
                    General Distributions or Targeted Distributions. To attest to
                    Phase 2 or Phase 3 General Distributions, please visit the{' '}
                    <a
                      href={config('PORTAL2_LINKHEALTH_URL')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Provider Relief Fund Application and Attestation Portal.
                    </a>
                    <br />
                    <br />
                    For additional information, please visit{' '}
                    <a
                      href={config('CORONAVIRUS_GOV_URL')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.accessiblityLink}
                    >
                      hrsa.gov/provider-relief
                    </a>{' '}
                    or call the provider support line at (866) 569-3522; for TTY
                    dial 711.
                    <div className={classes.cardTextFooter}>
                      Billing ID Number (TIN):
                      <ul>
                        {restrictedTINs.map((tin) => (
                          <li key={tin}>{tin}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Then>
            </If>
            <If condition={error}>
              <Then>
                <Switch>
                  <Case condition={error?.code === 'InvalidData'}>
                    <Alert className={classes.alertMessage}>
                      <Alert.Title>Data Mismatch</Alert.Title>
                      {getMessage('confirm-tins[InvalidData]')}
                    </Alert>
                  </Case>
                  <Default>
                    <Alert
                      errorCode={error?.code}
                      className={classes.alertMessage}
                    >
                      <Alert.Title>Error</Alert.Title>
                      {error?.message}
                    </Alert>
                  </Default>
                </Switch>
              </Then>
            </If>
          </Flex.Content>
        </Flex>

        <If condition={validTINs.length}>
          <Then>
            <ReCaptcha
              sitekey={RECAPTCHA_KEY}
              count={validTINs.length}
              onClick={handleReCaptchaClick}
              label="confirm_tins"
            >
              <Button
                onClick={handleContinue}
                isDisabled={!formState.valid}
                isLoading={isLoading}
                className={classes.continueButton}
              >
                Continue
              </Button>
            </ReCaptcha>
          </Then>
        </If>
      </PageLayout>
    </React.Fragment>
  );
};