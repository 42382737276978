/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { event } from '@abyss/web/tools/event';
import { Button } from '@abyss/web/ui/Button';
import { config } from '@abyss/web/tools/config';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { PageHeader } from '@hhs/ui/src/common/PageHeader';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';
import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { IconFinancialProtection } from '@hhs/ui/src/base/Icon/Provider/IconFinancialProtection';
import { IconCoverage } from '@hhs/ui/src/base/Icon/Provider/IconCoverage';
import { getFeature } from '@hhs/api/src/tools/features';
import { useSteps } from 'src/state/steps';

import { styles } from './BillingEntityConfirm.styles';
import {useNavigate} from "react-router-dom";

export const BillingEntityConfirm = () => {
  const classes = useStyles(styles);
  const form = useForm();
  const { setStep } = useSteps();
  const [selection, setSelection] = useState();
  const hooText = getFeature('HRSA_PRF_HOO');
  const navigate = useNavigate();

  useEffect(() => {
    setStep(1);
    event('BILLING_ENTITY_PAGE_VIEW');
  }, []);

  const handleChange = e => {
    setSelection(e.target.value);
  };

  const handleContinue = () => {
    if (selection) {
      if (selection === 'yes') {
        event('BILLING_ENTITY_CONTINUE', { linkName: 'yes-continue' });
        form.reset('taxIdNumbers.tins', '');
        navigate('/step/2');
      } else {
        event('BILLING_ENTITY_CONTINUE', { linkName: 'no-continue' });
        navigate('/not-eligible');
      }
    }
  };

  return (
    <React.Fragment>
      <PageHeader showBackButton={false}>
        <PageHeader.Title>Step 1 Eligibility</PageHeader.Title>
        The Department of Health and Human Services (HHS) has announced $178
        billion in relief funds, including to hospitals and other healthcare
        providers on the front lines of the coronavirus response. This funding
        supports healthcare-related expenses or lost revenue attributable to
        COVID-19. This site is open to all providers who want to apply for a
        Provider Relief Fund payment, regardless of network affiliation or payer
        contracts. HHS is contracting with UnitedHealth Group to facilitate
        delivery of the funds.
        <br />
        <br />
        <p className={classes.headerContent}>
          HHS has made publicly available the names of payment recipients and
          the amounts received, for all providers who attest to receipt of a
          payment and acceptance of the{' '}
          <a
            href="https://www.hrsa.gov/provider-relief/past-payments/terms-conditions"
            target="_blank"
            className={classes.accessiblityLink}
          >
            Terms and Conditions
          </a>{' '}
          or who retain payments for more than 90 days and are deemed to have
          accepted the{' '}
          <a
            href="https://www.hrsa.gov/provider-relief/past-payments/terms-conditions"
            target="_blank"
            className={classes.accessiblityLink}
          >
            Terms and Conditions.
          </a>{' '}
          By accepting funds, the recipient consents to the Department of Health
          and Human Services publicly disclosing the payments that recipient has
          received from the Provider Relief Fund.
        </p>
      </PageHeader>
      <PageLayout>
        <Flex>
          <Flex.Content className={classes.section}>
            <Flex direction='row' alignContent='stretch' className={classes.section}>
                <IconCoverage className={classes.headerIcon} size={32} />
                <h2 tabIndex="-1">Eligibility</h2>
                <div className={classes.horizontalLine} />
            </Flex>
            <div className={classes.privacyStatement}>
              <div className={classes.subHeading}>
                You must sign an attestation confirming receipt of the funds and
                agree to the{' '}
                <a
                  href="https://www.hrsa.gov/provider-relief/past-payments/terms-conditions"
                  target="_blank"
                  className={classes.accessiblityLink}
                >
                  Terms and Conditions
                </a>{' '}
                within 90 days of Automated Clearing House payment or 90 days of
                check payment issuance. Should you choose to reject the funds,
                you must also complete the attestation to indicate this. This
                Payment Portal will guide you through the attestation process to
                accept or reject the funds.
              </div>
              <p>
                Do you or your organization meet <b>one or more</b> of the
                following criteria?
              </p>
              <ul className={classes.bulletPoint}>
                <li>
                  Billing entity that received Medicare fee-for-service (FFS)
                  payments from the Centers for Medicare and Medicaid Services
                  (CMS) in 2019
                </li>
                <li>
                  Rural acute care general hospital, Critical Access Hospital
                  (CAH), Rural Health Clinic (RHC), or Community Health Center
                  located in a rural area
                </li>
                <li>
                  Rural Health Clinic (RHC) that has a Centers for Medicare and
                  Medicaid Services (CMS) Certification Number (CCN) and is
                  listed in either in the CMS Provider of Service file or the
                  CMS Survey & Certification's Quality, Certification and
                  Certification and Oversight Reports (QCOR)*
                </li>
                <li>
                  Indian Health Service (IHS), Tribal or Urban Indian Health
                  program
                </li>
                <li>Skilled Nursing Facility (SNF)</li>
              </ul>
              <RadioGroup
                onChange={handleChange}
                value={selection}
                name="billing-entity"
                aria-labelledby="billing-entity-label"
              >
                <RadioGroup.Radio value="yes" label="Yes" id="billing-entity-yes-1" />
                <RadioGroup.Radio value="no" label="No" id="billing-entity-no-1" />
              </RadioGroup>
              <div className={classes.middleFootnote} id="billing-entity-label">
                *This website / portal is primarily used to administer
                attestation and payment of relief funds from the CARES Act, the
                Paycheck Protection Program and Health Care Enhancement Act, and
                the American Rescue Package. It is also used to administer
                attestation and payment of relief funds from other HHS programs.
                These programs may have separate Terms and Conditions. For
                additional information, please visit{' '}
                <a
                  href="https://www.hrsa.gov/provider-relief"
                  target="_blank"
                  className={classes.accessiblityLink}
                >
                  hrsa.gov/provider-relief
                </a>{' '}
                or call the provider support line at (866) 569-3522; for TTY
                dial 711. {hooText}
              </div>
            </div>
          </Flex.Content>
        </Flex>
        <Flex>
          <Flex.Content>
            <Flex direction='row' alignContent='stretch' className={classes.section}>
              <IconFinancialProtection className={classes.headerIcon} size={36} />
                <h2 tabIndex="-1">Privacy Act Statement</h2>
                <div className={classes.horizontalLine2} />
            </Flex>
            <div className={classes.privacyStatement}>
              <div className={classes.privacyStatementSection}>
                <p>
                  <b>
                    The following statement serves to inform you of the purpose
                    for collecting personal information required by the{' '}
                    <a
                      href={config('COVID_LINKHEALTH_URL')}
                      target="_blank"
                      className={classes.accessiblityLink}
                    >
                      covid19.linkhealth.com{' '}
                    </a>
                    website and how it will be used.
                  </b>
                </p>
                <p>
                  AUTHORITY: 31 U.S.C. 3512, 3711, 3716, 3721, 1321; note E.O.
                  13520
                </p>{' '}
                <p>
                  PURPOSE: To collect information to determine eligibility for
                  Provider Relief Fund payments and process payment to you.
                </p>
                ROUTINE USES: The information collected is used by HHS to
                determine eligibility for payments from the Public Health and
                Social Services Fund, maintain an accounting of payments, and
                process payments from the Fund. Examples of other permissible
                uses include, but are not limited to, a contractor (and/or to
                its subcontractor) who has been engaged to perform services on
                an automated data processing (ADP) system used in processing
                financial transactions, to appropriate law enforcement agencies
                when relevant to an investigation, to the Treasury Department,
                and to auditing organizations conducting financial or compliance
                audits. A complete list of routine uses may be found at{' '}
                <a
                  href={config('FEDERAL_REGISTER_URL')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.accessiblityLink}
                >
                  {config('FEDERAL_REGISTER_URL')}
                </a>
              </div>
              <div className={classes.privacyStatementSection}>
                DISCLOSURE: Voluntary. If you choose not to provide your
                information, absence of the requested information may result in
                administration delays or the inability to process payments to
                you under the CARES Act.
              </div>
            </div>
          </Flex.Content>
        </Flex>
        <Button
          onClick={handleContinue}
          isDisabled={!selection}
          className={classes.continueButton}
          size={'$lg'}
        >
          Continue
        </Button>
      </PageLayout>
    </React.Fragment>
  );
};