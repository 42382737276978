import React, { useEffect } from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { event } from '@abyss/web/tools/event';
import { classNames } from '@abyss/web/tools/classNames';
//import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from 'src/app/App/PageHeader';
import { PageLayout } from 'src/app/App/PageLayout';
import { Flex } from '@abyss/web/ui/Flex';
import { IconCatalog } from '@hhs/ui/src/base/Icon/Provider/IconCatalog';
import { useAttestation } from 'src/state/attestation';
import { useSteps } from 'src/state/steps';
import { Button } from '@abyss/web/ui/Button';
import { IconPrint } from '@hhs/ui/src/base/Icon/Provider/IconPrint';
import { config } from '@abyss/web/tools/config';
import { styles } from './Attestations.styles';
import { AttestationCard } from './AttestationCard';
import { CheckAttestationCard } from './CheckAttestationCard';
import {useNavigate} from "react-router-dom";

export const Attestations = () => {
  const classes = useStyles(styles);
  const { setStep } = useSteps();
  const { attestations } = useAttestation();
  const navigate = useNavigate();
  const validTINs = Object.values(attestations).filter(item => item.valid);
  const ACHTINs = validTINs.filter(item => !item.checkPayment);
  const checkTINS = validTINs.filter(item => item.checkPayment);
  const invalidTINs = Object.values(attestations).reduce((arr, item) => {
    return arr.concat(!item.valid ? item.maskedTin : []);
  }, []);

  const allComplete = validTINs.every(item => item.confirmationId);

  useEffect(() => {
   
     // handle the page refersh event.
     if(Object.values(attestations).length == 0) {      
      
      setStep(1);
      navigate('/step/1');
    } else {
  
    setStep(4);
    const someComplete = validTINs.some(item => item.confirmationId);
    
    if (someComplete) {
      if (!allComplete) {
        const referenceNo = validTINs.filter(item => item.confirmationId);
        const confirmedTINS = validTINs.filter(item => item.confirmationId);
        const acceptedTIN = confirmedTINS.filter(item => item.accepted);
        const nonacceptedTIN = confirmedTINS.filter(item => !item.accepted);
        const ineligibleTIN = Object.values(attestations).filter(
          item => !item.valid
        );
        event('ATTESTATION_PARTIAL_COMPLETE_PAGE_VIEW', {
          referenceNo: referenceNo.map(item => item.confirmationId).join('|'),
          acceptedTIN: acceptedTIN.map(item => item.tin).join('|'),
          nonacceptedTIN: nonacceptedTIN.map(item => item.tin).join('|'),
          ineligibleTIN: ineligibleTIN.map(item => item.tin).join('|'),
        });
      }
    } else {
      event('ATTESTATIONS_PAGE_VIEW');
    }
  }
  }, []);

  useEffect(() => {

     // handle the page refersh event.
     if(Object.values(attestations).length == 0) {     
      
      setStep(1);
      navigate('/step/1');
    } else {
     
    if (allComplete) {
      setStep(5);

      const confirmedTINS = validTINs.filter(item => item.confirmationId);
      const acceptedTINs = confirmedTINS.filter(item => item.accepted);
      const nonAcceptedTINs = confirmedTINS.filter(item => !item.accepted);
      const acceptedTINRecord = acceptedTINs.map(acceptedTIN => ({
        acceptedTIN: acceptedTIN.tin,
        referenceNo: acceptedTIN.confirmationId,
        billingAddressState: acceptedTIN.billingAddress.state.label,
      }));
      const nonacceptedTINRecord = nonAcceptedTINs.map(nonAcceptedTIN => ({
        nonAcceptedTIN: nonAcceptedTIN.tin,
        referenceNo: nonAcceptedTIN.confirmationId,
        billingAddressState: nonAcceptedTIN.billingAddress.state.label,
      }));
      const ineligibleTIN = Object.values(attestations).filter(
        item => !item.valid
      );

      event('ATTESTATION_COMPLETE_PAGE_VIEW', {
        referenceNo: confirmedTINS.map(item => item.confirmationId).join('|'),
        acceptedTIN: acceptedTINs.map(item => item.tin).join('|'),
        nonacceptedTIN: nonAcceptedTINs.map(item => item.tin).join('|'),
        ineligibleTIN: ineligibleTIN.map(item => item.tin).join('|'),
        acceptedTINRecord,
        nonacceptedTINRecord,
      });
      navigate('/confirmation');
    }
  }
  }, [allComplete]);

  return (
    <React.Fragment>
      <Switch>
        <Case condition={allComplete}>
          <PageHeader showBackButton={false}>
            <PageHeader.Title>Confirmation</PageHeader.Title>
            Thank you. Your Information has been received for the TIN(s) below.
            You will receive a confirmation email with reference number(s). You
            may print this page for your records.
          </PageHeader>
        </Case>
        <Default>
          <PageHeader>
            <PageHeader.Title>Step 4 Attestations</PageHeader.Title>
          </PageHeader>
        </Default>
      </Switch>
      <PageLayout>
        <Flex>
          <Flex.Content space-self>
            <IconCatalog
              className={classes.headerIcon}
              size={36}
              style={{ visibility: allComplete ? 'hidden' : 'initial' }}
            />
          </Flex.Content>
          <Flex.Content className={classes.full}>
            <Switch>
              <Case condition={allComplete}>
                <Flex justify={'end'}>
                  <Flex.Content />
                  <Flex.Content>
                    <Button
                      variant="outline"
                      onClick={() => window.print()}
                      before={<IconPrint />}
                      className={classes.printButton}
                      size={'$lg'}
                    >
                      Print
                    </Button>
                  </Flex.Content>
                </Flex>
              </Case>
              <Default>
                <Flex direction='row' alignContent='stretch'>
                  <Flex.Content>
                    <h2>Attestations</h2>
                  </Flex.Content>
                  <Flex.Content className={classes.liner}>
                    <div className={classes.horizontalLine} />
                  </Flex.Content>
                </Flex>
                <div className={classes.subHeading}>
                  Please review the information below and complete the
                  attestation process for each eligible Billing TIN.
                </div>
              </Default>
            </Switch>
            <div className={classes.cardContainer}>
              <If condition={ACHTINs.length}>
                <Then>
                  <div className={classes.section}>
                    <h4 className={classes.labelHeader}>
                      Automated Clearing House(ACH) Deposit
                    </h4>
                    <Flex gutters={true}>
                      { ACHTINs.map((item) => (
                        <Flex.Content space-100 space-medium-50 key={item.uuid}>
                          <AttestationCard attestation={item} />
                        </Flex.Content>
                      ))}
                    </Flex>
                  </div>
                </Then>
              </If>
              <If condition={checkTINS.length}>
                <Then>
                  <div className={classes.section}>
                    <h4 className={classes.labelHeader}>Paper Check Deposit</h4>
                    <Flex gutters={true}>
                    { checkTINS.map((item) => (
                        <Flex.Content space-100 space-medium-50 key={item.uuid}>
                          <CheckAttestationCard attestation={item} />
                        </Flex.Content>
                    ))}
                    </Flex>
                  </div>
                </Then>
              </If>
            </div>
            <If condition={invalidTINs.length}>
              <Then>
                <div className={classNames(classes.section, classes.cardContent)}>
                  <h4 className={classes.cardHeader}>
                    <b>Ineligible TIN(s)</b>
                  </h4>
                  <div className={classes.cardText}>
                    The Billing TIN(s) you entered may be eligible for Relief Fund
                    payment; however, we do not have banking information on file.
                    Please return to this site once you have received payment via
                    Automated Clearing House (ACH) deposit or paper check. If you
                    believe you have received this message in error, please visit{' '}
                    <a
                      href={config('CORONAVIRUS_GOV_URL')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.accessiblityLink}
                    >
                      hrsa.gov/provider-relief
                    </a>{' '}
                    or call the Provider Support Line at (866) 569-3522.
                    <div className={classes.cardTextFooter}>
                      Billing ID Number (TIN):
                      <ul>
                        { invalidTINs.map((tin) => (
                          <li key={tin}>{tin}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Then>
            </If>
          </Flex.Content>
        </Flex>
      </PageLayout>
    </React.Fragment>
  );
};
