import React, { useEffect } from 'react';
import { useUser } from 'src/state/user';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { honeypot } from 'src/tools/honeypot';
import { registerAnalytics } from 'src/tools/analytics';
import { attachStylesheet } from '../../styles/stylesheet';
import { config } from '@abyss/web/tools/config';
import { init as initApm } from '@elastic/apm-rum';

export const App = () => {

  //Disabled New Relic analytics.
  registerAnalytics();
  honeypot();
  attachStylesheet();
  
  const { getUserSession } = useUser();

  initApm({
    serviceName: config('ELASTIC_APM_SERVICE_NAME'),
    serverUrl: config('ELASTIC_APM_SERVER_URL'),    
    environment: config('ELASTIC_APM_ENV'), 
    pageLoadSampled: true,
    logLevel: 'trace',
    active: true,
    apmRequest: true,
  });

  useEffect(() => {
    getUserSession();
  }, []);
  
  return (
    <React.Fragment>
      <Header />
      <Content />
      <Footer />
    </React.Fragment>
  );
};
